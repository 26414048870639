import styled from 'styled-components';

// Header
export const Wrapper = styled.header`
  position: fixed;
  width: 100%;
  z-index: 9999;
`;

// Topo
export const Topo = styled.section`
  background: #7A7A7A;
  width: 100%;

  .container {
    display: flex;
    color: #fff;
    padding: 10px 0;

    @media screen and (max-width: 480px) {
      display: inline-block;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
    }

    p { 
      margin-bottom: 0;

      span {
        display: inline-block;
        margin: 0 7.5px;
      }
      a {
        color: #fff;
      }
    }

    div {
      margin-right: 30px;
      font-size: 13px;

      @media screen and (max-width: 480px) {
        text-align: center;
        margin-right: 0;
      }
      
      img {
        margin-right: 7.5px;
        height: 20px;
      }

      @media screen and (max-width: 480px) {
        &:last-of-type,
        &:nth-of-type(2) {
          display: none;
        }
      }
    }

    .fones {
      display: flex; 
      justify-content: center;
    }
  }
`;

// Content
export const Content = styled.section`
  padding: 17.5px 0;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0, .075);
  width: 100%;
  background: #FFF;

  @media screen and (max-width: 480px) {
    padding: 15px 0;
  }

  .container {
    display: grid;
    grid-template-columns: 400px 1fr;
    align-items: center;

    @media screen and (max-width: 480px) {
      grid-template-columns: 100%;
    }

    img {
      height: 50px;
    }

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      @media screen and (max-width: 480px) {
        display: none;
      }

      li {
        list-style: none;

        a {
          color: #707070;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .marca {
      text-align: center;
      
      img {
        height: 40px;
      }
    }
  }
`;