import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Select, Modal, notification } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// email
// aaaaaaaaaaa
import ademail from '../../services/ademail';

// api
import api from '../../services/api';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// styles
import {
  Banner, SobreNos, Seguradoras, Seguros, Consorcios, Blog, Mapa, Contatos
} from './styles';

// select
const { Option } = Select;

export default function Home() {

  // states
  const [textosHome, setTextosHome] = useState(null);
  const [seguradoras, setSeguradoras] = useState(null);
  const [seguros, setSeguros] = useState(null);
  const [consorcios, setConsorcios] = useState(null);
  const [posts, setPosts] = useState(null);

  // states modal
  const [visibleModal, setVisibleModal] = useState(false);

  // modal
  function abrirModal( ){
    setVisibleModal(true)
  }

  // handleChange modalidade
  const [modalidade, setModalidade] = useState(null);
  function handleChangeModalidade(value) {
    setModalidade(value);
  }

  // formatar data
  function formataData( data ) {
    var date = new Date(data);

    let dataFormatada = date.toLocaleString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).replace(/\//g, '/');

    return dataFormatada;
  }

  // form para cotação
  const [disabled, setDisabled] = useState(false);
  const cotacao = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      modalidade: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      // modalidade: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
        <p>Nome: ${values.nome}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.telefone}</p>
        <p>Modalidade: ${modalidade}</p>
      `;

      const mail = {
        to: 'falecom@agilpressseguros.com.br',
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(['web@agenciaade.com.br', 'fernanda@agilpressseguros.com.br']),
        subject: 'Novo contato via formulário do site - AgilPress',
        message: body,
      };

      try {
        // Sucesso ao enviar
        setDisabled(true);
        await ademail.post('/email', mail);

        notification.success({
          message: 'Cotação solicitada com sucesso sucesso. Em breve entraremos em contato! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
        setDisabled(false);
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  // form de contato
  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
        <p>Nome: ${values.nome}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.telefone}</p>
        <p>Mensagem: ${values.mensagem}</p>
      `;

      const mail = {
        to: 'falecom@agilpressseguros.com.br',
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(['web@agenciaade.com.br', 'fernanda@agilpressseguros.com.br']),
        subject: 'Nova solicitação de cotação - AgilPress',
        message: body,
      };

      try {
        // Sucesso ao enviar
        setDisabled(true);
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
        setDisabled(false);
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });
  
  // textosHome
  useEffect(() => {
    async function loadTextosHome() {
      await api
        .get('/pages?slug=home', {})
        .then(response => {
          setTextosHome( response.data[0] );
        });
    }
    loadTextosHome();
  }, []);

  // seguradoras
  useEffect(() => {
    async function loadSeguradoras() {
      await api
        .get('/seguradoras?order=asc&orderby=title&per_page=40', {})
        .then(response => {
          setSeguradoras(response.data);
        });
    }
    loadSeguradoras();
  }, []);

  // seguros
  useEffect(() => {
    async function loadSeguros() {
      await api
        .get('/seguros?order=asc&orderby=title&per_page=40', {})
        .then(response => {
          setSeguros(response.data);
        });
    }
    loadSeguros();
  }, []);

  // consorcios
  useEffect(() => {
    async function loadConsorcios() {
      await api
        .get('/consorcios', {})
        .then(response => {
          setConsorcios(response.data);
        });
    }
    loadConsorcios();
  }, []);

  // posts
  useEffect(() => {
    async function loadPosts() {
      await api
        .get('/posts?_embed', {})
        .then(response => {
          setPosts(response.data);
        });
    }
    loadPosts();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          AgilPress Seguros e Consórcios
        </title>
        <meta
          name="description"
          content="Atendendo o mercado em diversas áreas e cenários surgiu então a demanda de proteger não só a marca, mas também os bens de nossos clientes, nossa expertise mostrou que a tranquilidade está relacionada à proteção total do seu patrimônio."
        />
        <meta
          name="keywords"
          content="seguros, consórcios"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>

      <Header />

      <main>
        <Banner id="banners">
          <div className="container">
            <h1>É preciso garantir a proteção do que amamos e conquistamos, pois não temos o controle de tudo!</h1>
            <h2>Solicite uma cotação e nós encontraremos a melhor solução para você.</h2>

            <form onSubmit={cotacao.handleSubmit}>
                <div>
                  <label htmlFor="nome">Nome</label>
                  <input
                    type="text"
                    name="nome"
                    id="nome"
                    onChange={cotacao.handleChange}
                    onBlur={cotacao.handleBlur}
                    value={cotacao.values.nome}
                  />
                  {cotacao.touched.nome && cotacao.errors.nome ? (
                    <span className="erro">{cotacao.errors.nome}</span>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={cotacao.handleChange}
                    onBlur={cotacao.handleBlur}
                    value={cotacao.values.email}
                  />
                  {cotacao.touched.email && cotacao.errors.email ? (
                    <span className="erro">{cotacao.errors.email}</span>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="telefone">Celular/Telefone</label>
                  <Input
                    type="tel"
                    name="telefone"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    id="telefone"
                    onChange={cotacao.handleChange}
                    onBlur={cotacao.handleBlur}
                    value={cotacao.values.telefone}
                  />
                  {cotacao.touched.telefone && cotacao.errors.telefone ? (
                    <span className="erro">{cotacao.errors.telefone}</span>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="modalidade">Escolha a modalidade de seguro</label>

                  <Select defaultValue="Selecione uma modalidade" onChange={handleChangeModalidade}>
                    {!!seguros && seguros.map( (seguro, index) => (
                      <Option value={seguro.title.rendered}>{seguro.title.rendered}</Option>
                    ))}
                  </Select>
                  {/* {cotacao.touched.modalidade && cotacao.errors.modalidade ? (
                    <span>{cotacao.errors.modalidade}</span>
                  ) : null} */}
                </div>

                <button type="submit" className={disabled ? 'disable' : ''}>Quero uma cotação</button>
            </form>

            <p>Em caso de sinistro, calma! Vamos resolver isso juntos.</p>
            <button className="acionar" onClick={() => abrirModal()}>Preciso de ajuda</button>
          </div>

          <img src={require('../../assets/banner.jpg')} alt="AgilPress" />
        </Banner>

        <SobreNos id="sobre-nos">
          <div className="container">
            <h2>Sobre <strong>nós</strong></h2>
            {!!textosHome && ReactHtmlParser(textosHome.acf.sobre_nos) }
          </div>

          <div className="ilustracao"></div>

          <div className="ilustracao ilustracao--dois"></div>
        </SobreNos>

        <Seguradoras id="seguradoras">
          <div className="topo">
            <img src={require('../../assets/bg_seguradoras.png')} alt="Background Seguradoras" />
            <div className="container">
              <h2>Seguradoras</h2>
              <p>Qualidade e confiança</p>
            </div>
          </div>

          <div className="grid">
            {!!seguradoras && seguradoras.map( (seguradora, index) => (
              <img src={seguradora.acf.logo.url} alt={index} />
            ))}
          </div>
        </Seguradoras>

        <Seguros id="seguros">
          <div className="topo">
            <img src={require('../../assets/bg_seguros.png')} alt="Background Seguradoras" />
            <div className="container">
              <h2>Seguros</h2>
              <p>Temos o seguro ideal para você</p>
            </div>
          </div>

          <div className="container">
            <div className="grid">

            {!!seguros && seguros.map( (seguro, index) => (
              <a href={`seguros/${seguro.slug}`}>
                <div className="box">
                  <div>
                    <img className="losango" src={require('../../assets/losango_icone.svg')} alt="Ícone Losango" />
                    <img src={seguro.acf.icone} alt={seguro.title.rendered} />
                  </div>
                  <h3>{seguro.acf.titulo_na_home}</h3>
                </div>
              </a>
            ))}
            </div>
          </div>
        </Seguros>

        <Consorcios id="consorcios">
          <div className="topo">
            <img src={require('../../assets/bg_consorcios.png')} alt="Background Seguradoras" />
            <div className="container">
              <h2>Consórcios</h2>
              <div className="texto">
                {!!textosHome && ReactHtmlParser(textosHome.acf.consorcios) }
              </div>
            </div>
          </div>

          <div className="container">
            <div className="grid">
              {!!consorcios && consorcios.map( (consorcio, index) => (
                <a href={`consorcios/${consorcio.slug}`}>
                  <div className="box">
                    <div>
                      <img className="losango" src={require('../../assets/losango_consorcio.svg')} alt="Ícone Losango" />
                      <img src={consorcio.acf.icone} alt={consorcio.title.rendered} />
                    </div>
                    <h3>{consorcio.acf.titulo_na_home}</h3>
                  </div>
                </a>
              ))}
            </div>
          </div>

          <img className="casal" src={require('../../assets/casal.png')} alt="Casal"/>
        </Consorcios>

        <Blog id="blog">
          <div className="topo">
            <img src={require('../../assets/bg_blog.png')} alt="Background Seguradoras" />
            
            <div className="container">
              <h2>Blog</h2>
            </div>
          </div>

          <div className="grid container">
            {!!posts && posts.map( (post) => (
              <a href={`blog/${post.slug}`}>
                <article>
                  <img
                        src={
                          post.featured_media
                            ? post._embedded['wp:featuredmedia']['0'].source_url
                            : 'https://www.uergs.edu.br/themes/modelo-noticias/images/outros/GD_imgSemImagem.png?classificacao=34752'
                        }
                        alt={post.title.rendered}
                      />
                  <span>{formataData(post.date)}</span>
                  <h3>{post.title.rendered}</h3>
                  <div>{ReactHtmlParser(post.excerpt.rendered)}</div>
                  <button>Continuar lendo</button>
                </article>
              </a>
            ))}
          </div>

          <hr />
          <a className="vejaMais" href="/blog">Veja todas as notícias</a>
        </Blog>

        <Mapa>
          <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.9437676821785!2d-50.154564984993065!3d-25.06989498395552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94e81a280074948d%3A0x9e2dbdb6e2327343!2sR.%20Pedro%20Mascarenhas%20Ribas%20-%20Jardim%20Carvalho%2C%20Ponta%20Grossa%20-%20PR%2C%2084015-760!5e0!3m2!1spt-BR!2sbr!4v1596490880371!5m2!1spt-BR!2sbr" width="100%" height="500" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        </Mapa>

        <Contatos id="contato" className="container">
          <h2>Contato</h2>

          <div>
            <address>
              <p>Rua Pedro Mascarenhas Ribas, 412</p>
              <p>CEP 84.015-760 - Ponta Grossa, PR</p>
              <p>falecom@agilpressseguros.com.br</p>
              <strong>(42) 99127-9326 (WhatsApp)</strong><br/>
              <strong>(42) 3323-7671</strong>
            </address>

            <div className="social">
              <h4>Siga-nos nas redes sociais!</h4>
              <div>
                <a href="http://facebook.com" target="__blank">
                  <img src={require('../../assets/facebook.svg')} alt="Ícone Facebook" />
                </a>
                <a href="https://www.instagram.com" target="__blank">
                  <img src={require('../../assets/instagram.svg')} alt="Ícone Instagram" />
                </a>
              </div>
            </div>

            <img className="brasil" src={require('../../assets/brasil.svg')} alt="Mapa do Brasil" />

            <hr />

            <h4>Atendemos<br/>em todo o Brasil!</h4>

            <h3>Envie uma mensagem para nós.</h3>

            <form onSubmit={formik.handleSubmit}>
              <div id="inputs">
                <div>
                  <label htmlFor="nome">Nome:</label>
                  <input
                    type="text"
                    name="nome"
                    // placeholder="* NOME COMPLETO"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    name="email"
                    // placeholder="* E-MAIL"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span>{formik.errors.email}</span>
                  ) : null}
                </div>
                
                <div>
                  <label htmlFor="telefone">Telefone:</label>
                  <Input
                    type="tel"
                    name="telefone"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    // placeholder="* TELEFONE"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telefone}
                  />
                  {formik.touched.telefone && formik.errors.telefone ? (
                    <span>{formik.errors.telefone}</span>
                  ) : null}
                </div>
              </div>
                  
              <label htmlFor="mensagem">Mensagem:</label>
              <textarea
                name="mensagem"
                cols="30"
                rows="5"
                // placeholder="MENSAGEM"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mensagem}
              />
              {formik.touched.mensagem && formik.errors.mensagem ? (
                <span>{formik.errors.mensagem}</span>
              ) : null}
              <button type="submit" className={disabled ? 'disable' : ''}>
                ENVIAR
              </button>
            </form>

            <hr />

            <p className="sinistro">Em caso de sinistro, solicite auxílio que entraremos em contato o mais breve possível.</p>
            
            <button className="acionar" onClick={() => abrirModal()}>Acionar assistência de sinistro</button>
          </div>
        </Contatos>

      </main>
      
      <Footer />

      <div>
        <Modal
          visible={visibleModal}
          onCancel={() => setVisibleModal(false)}
          footer={null}
          title={null}
          width={600}
        >
          <div className="topo">
              <h2>Seguradoras</h2>
              <p>Comunique imediatamente o seu corretor de seguros, ele é o profissional capacitado para lhe passar todas as orientações necessárias. Caso não consiga contato com o seu corretor, ligue para a central telefônica 24h da sua seguradora.</p>
          </div>

          <div className="grid">
            {!!seguradoras && seguradoras.map( (seguradora, index) => (
              <div className="box" key={index}>
                <img src={seguradora.acf.logo.url} alt={index} />
                <h3>{seguradora.acf.telefone}</h3>
              </div>
            ))}
          </div>

          <a target="__blank" href="https://api.whatsapp.com/send?phone=5542991279326&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20%20">
            <button>Falar agora com a corretora</button>
          </a>
        </Modal>
      </div>
    </>
  );
}
