import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
    font-family: 'Lato', sans-serif;
  }
  
  body {
    background: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  @media screen and (max-width: 480px) {
    html,
    body {
      overflow-x: hidden;
    }
  }

  .container {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;

    @media screen and (max-width: 480px) {
      max-width: 87.5%;
      width: 87.5%;
    }
  }

  @media screen and (max-width: 480px) {
    * {
      /* border: 1px solid red; */
    }
  }

  .ant-modal-mask {
    z-index: 999999998;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .ant-modal-wrap {
    z-index: 999999999;
    
  }

  .ant-modal {
    width: 100% !important;
    max-width: 1000px;
    top: 5%;

    @media screen and (max-width: 480px) {
      width: 95% !important;
      max-width: 95% !important;
      top: 2.5%;
    }

    .ant-modal-body {
      .topo {
        text-align: left;
        padding: 10px 10%;

        h2 {
          position: relative;
          font-size: 40px;
          color: #707070;
          font-weight: 900;
          margin-bottom: 0;
          margin-left: 0;
          width: 270px;

          :after {
            content: url(${require('../assets/icone.svg')});
            position: absolute;
            left: -60px;
            top: 16px;

            @media screen and (max-width: 480px) {
              transform: scale(.75);
              top: 2.5px;
            }
          }

          @media screen and (max-width: 480px) {
            width: 100%;
            margin-left: 5px;
            font-size: 30px;
          }
        }
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(5,15%);
        grid-gap: 35px 6.25%;
        padding: 20px 5% 40px 5%;

        @media screen and (max-width: 480px) {
          grid-template-columns: 45% 45%;
          grid-gap: 30px 10%;
        }

        div {
          text-align: center;

          img {
            width: 100%;
            max-width: 100%;
            max-height: 55px;
            height: 55px;
            object-fit: contain;
          }

          h3 {
            font-size: 16px;
            font-weight: bold;
            margin-top: 7.5px;
          }
        }
      }

      button {
        background: #4AC959;
        color: #fff;
        width: auto;
        margin: 0 auto 25px auto;
        display: block;
        border: none;
        border-radius: 50px;
        font-weight: 900;
        font-size: 16px;
        padding: 5px 25px;
        cursor: pointer;
        transition: all ease-in .3s;
        

        :hover {
          background: #4AC959;
        }
      }
    }
  }
`;
