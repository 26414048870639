import styled from 'styled-components';

// Wrapper
export const Wrapper = styled.footer`
  background: #F8F8F8;
  margin-top: 75px;
  padding: 45px 0 20px 0;
  text-align: center;
  color: #535352;

  .container {
    h3 {
      font-size: 22px;
      text-transform: uppercase;
      margin: 20px 0;
      color: #535352;
    }

    .social {
      height: 40px;
      margin: 0 7.5px;
    }

    .textos {
      margin: 30px 0 15px 0;

      p {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #535352;
    }
    
    .ade {
      color: #535352;

      span {
        font-weight: 900;
        color: #DE1A2B;
      }
    }
  }
`;