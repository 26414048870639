import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// components
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

// api
import api from '../../../services/api';

// styles
import { Wrapper, CTA } from './styles';

export default function Post( {match} ) {
  const { slug } = match.params;
  const [dados, setDados] = useState();

  useEffect(() => {
    async function loadDados() {
      await api
        .get(`/posts?slug=${slug}`, {})
        .then(response => {
          setDados(response.data);
        });
    }
    loadDados();
  }, [slug]);


  return (
    <>
      <Helmet>
        <title>
          {`${
            !!dados ? dados[0].title.rendered : 'Blog'
          } - AgilPress`}
        </title>

        <meta
          name="description"
          content="Atendendo o mercado em diversas áreas e cenários surgiu então a demanda de proteger não só a marca, mas também os bens de nossos clientes, nossa expertise mostrou que a tranquilidade está relacionada à proteção total do seu patrimônio."
        />
        <meta
          name="keywords"
          content="seguros, consórcios"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>

      <Header internas />

      {!!dados && dados.map( (post,index) => (
        <main key={index}> 
          <Wrapper>
            <div className="topo">
              <div className="container">
                <h1>{post.title.rendered}</h1>
              </div>
            </div>

            <div className="container">
              <div className="conteudo">
                {ReactHtmlParser(post.content.rendered)}
              </div>
            </div>
          </Wrapper>

          <CTA>
            <div className="container">
              <p>Deseja fazer uma cotação?</p>
              <a href="#!">
                <button>Solicitar cotação agora</button>
              </a>
            </div>

            <img alt="" className="icone" src={require('../../../assets/seguros/losango.svg')} />
          </CTA>
        </main>
      ))}
      <Footer />
    </>
  );
}
