import React from 'react';

// styles
import { Wrapper, Topo, Content } from './styles';

// scroll
import { Link } from 'react-scroll';

export default function Header({ internas }) {

  return (
    <>
      <Wrapper>
        <Topo>
          <div className="container">
            <div className="fones">
              <img src={require('../../assets/telefone.svg')} alt="" />
              <p>
                <a href="tel:+5504233237671">(42) 3323-7671</a>
                <span>|</span>
                <a target="__blank" href="https://api.whatsapp.com/send?phone=5542991279326&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20%20">(42) 99127-9326 (WhatsApp)</a>
              </p>
            </div>

            <div>
              <img src={require('../../assets/email.svg')} alt="" />
              <span>falecom@agilpressseguros.com.br</span>
            </div>

            <div>
              <img src={require('../../assets/endereco.svg')} alt="" />
              <span>Ponta Grossa, PR</span>
            </div>
          </div>
        </Topo>

        <Content>
          <div className="container">
            

            {internas ? (
              <a href="/">
                <img src={require('../../assets/agilpress_marca.svg')} alt="AgilPress Seguros e Consórcios"  />
              </a>
            ) : (
              <Link
                smooth
                to="banners"
                className="marca"
                // onClick={() => setMenuOpen(false)}
              >
                <img src={require('../../assets/agilpress_marca.svg')} alt="AgilPress Seguros e Consórcios"  />
              </Link>   
            )}         
            <nav>
              <ul>
                <li>
                {internas ? (
                  <a href="/#banners">Home</a>
                ) : (
                  <Link
                      smooth
                      to="banners"
                      // onClick={() => setMenuOpen(false)}
                    >
                      Home
                  </Link>
                  )}
                </li>

                <li>
                {internas ? (
                  <a href="/#sobre-nos">Sobre nós</a>
                ) : (
                  <Link
                      smooth
                      to="sobre-nos"
                      ffset={-20}
                      // onClick={() => setMenuOpen(false)}
                    >
                      Sobre nós
                  </Link>
                  )}
                </li>

                <li>
                {internas ? (
                  <a href="/#seguradoras">Seguradoras</a>
                ) : (
                  <Link
                    smooth
                    to="seguradoras"
                    offset={-100}
                    // onClick={() => setMenuOpen(false)}
                  >
                    Seguradoras
                  </Link>
                  )}
                </li>

                <li>
                {internas ? (
                  <a href="/#seguros">Seguros</a>
                ) : (
                  <Link
                    smooth
                    to="seguros"
                    offset={20}
                    // onClick={() => setMenuOpen(false)}
                  >
                    Seguros
                  </Link>
                  )}
                </li>

                <li>
                {internas ? (
                  <a href="/#consorcios">Consórcios</a>
                ) : (
                  <Link
                    smooth
                    to="consorcios"
                    offset={50}
                    // onClick={() => setMenuOpen(false)}
                  >
                    Consórcios
                  </Link>
                  )}
                </li>

                <li>
                {internas ? (
                  <a href="/#blog">Blog</a>
                ) : (
                  <Link
                    smooth
                    to="blog"
                    offset={220}
                    // onClick={() => setMenuOpen(false)}
                  >
                    Blog
                  </Link>
                  )}
                </li>

                <li>
                {internas ? (
                  <a href="/#contato">Contato</a>
                ) : (
                  <Link
                    smooth
                    to="contato"
                    offset={-200}
                    // onClick={() => setMenuOpen(false)}
                  >
                    Contato
                  </Link>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </Content>
      </Wrapper>
    </>
  );
}
