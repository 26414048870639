import styled, { keyframes } from 'styled-components';

import 'antd/es/select/style/css';
import 'antd/es/modal/style/index.css';
import 'antd/es/notification/style/css';

// Banner
export const Banner = styled.section`
  padding-top: 135px;
  position: relative;
  
  .container {
    padding-bottom: 0px;
  }

  img {
    position: absolute;
    z-index: -1;
    top: 125px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

    @media screen and (max-width: 480px) {
      object-fit: contain;
      top: 100px;
      width: 115%;
    }
  }

  h1 {
    padding-top: 30px;
    color: #707070;
    width: 520px;
    font-size: 34px;
    font-weight: 300;
    line-height: 44px;
    
    @media screen and (max-width: 480px) {
      width: 100%;
      font-size: 22px;
      line-height: normal;
      padding-top: 5px;
      width: 52.5%;
      margin-bottom: 50px;
    }

    @media screen and (max-width: 320px) {
      font-size: 18px;
    }

    strong {
      color: #DE1A2B;
      font-weight: 700;
      font-size: 40px;

      @media screen and (max-width: 480px) {
        font-size: 34px;
      }
    }
  }

  h2 {
    width: 400px;
    color: #7B7B7A;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;

    @media screen and (max-width: 480px) {
      width: 100%;
      font-size: 20px;
      line-height: normal;
      padding-top: 70px;
    }

    @media screen and (max-width: 400px) {
      padding-top: 40px;
    }
  }

  form {
    width: 400px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    label {
      display: block;
    }

    input {
      width: 100%;
      background: #fff;
      border: none;
      height: 40px;
      margin-bottom: 10px;
      text-indent: 15px;
      border-radius: 5px;

      @media screen and (max-width: 480px) {
        border: 1px solid #d9d9d9;
      }
    }

    .erro {
      display: block;
      width: 100%;
      background: red;
      height: 30px;
      text-align: center;
      color: #fff;
      line-height: 30px;
      font-style: italic;
      margin-bottom: 10px;
    }
  }

  p {
    font-weight: bold;
    width: 350px;
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 50px;
    margin-bottom: 15px;

    @media screen and (max-width: 480px) {
      width: 100%;
    } 
  }

  button {
    background: #DE1A2B;
    color: #fff;
    width: auto;
    margin: 20px 0;
    display: block;
    border: none;
    height: 40px;
    border-radius: 50px;
    font-weight: 900;
    font-size: 18px;
    padding: 0 20px;
    cursor: pointer;
    transition: all ease-in .3s;

    :hover {
      background: #535352;
    }
    
    &.acionar { 
      margin-top: 10px;
      background: #4AC959;

      :hover {
        background: #455A64;
      }
    }

    @media screen and (max-width: 480px) {
      margin: 20px auto;
    }
  }

  .ant-select-single {
    width: 100%;
  }
`;

// Animacao
const animacaoCidade = keyframes`
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
`;

const animacaoCidade2 = keyframes`
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
`;

// Sobre Nós
export const SobreNos = styled.section`
  padding-top: 150px;
  padding-left: 40px;
  padding-bottom: 600px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    padding: 60px 0 300px 0;
  }

  p {
    width: 100%;
    font-size: 16px;
    line-height: 30px;
  }

  h2 {
    font-weight: 300;
    position: relative;
    font-size: 40px;
    color: #707070;

    @media screen and (max-width: 480px) {
      margin-left: 20px;
      font-size: 34px;
    }

    strong {
      font-weight: bold;
    }

    :after {
      content: url(${require('../../assets/icone.svg')});
      position: absolute;
      left: -60px;
      top: 16px;
    }
  }

  .ilustracao {
    background: url(${require('../../assets/cidade.png')});
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    background-size: contain;
    height: 750px;
    animation: ${animacaoCidade} 30s linear infinite;
    background-repeat: no-repeat;

    @media screen and (max-width: 480px) {
      height: 300px;
      width: 140%;
    }
  }

  .ilustracao--dois {
    animation: ${animacaoCidade2} 30s linear infinite;
  }
`;

// Seguradoras
export const Seguradoras = styled.section`
  padding-bottom: 50px;

  @media screen and (max-width: 480px) {
    margin-top: 20px;
    padding-bottom: 30px;
  }

  .topo {
    position: relative;
    @media screen and (max-width: 480px) {
      z-index: 9;
    }

    img {
      width: 100%;
      position: relative;
      z-index: -1;

      @media screen and (max-width: 480px) {
        width: 250%;
        left: -60%;
      }
    }

    div {
      position: absolute;
      z-index: 9;
      color: #fff;
      padding-left: 40px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-55%);
      top: 100px;

      @media screen and (max-width: 480px) {
        top: 20px;
      }

      h2 {
        font-weight: 300;
        position: relative;
        font-size: 40px;
        color: #fff;
        font-weight: 900;
        margin-bottom: 0;

        :after {
          content: url(${require('../../assets/icone.svg')});
          position: absolute;
          left: -60px;
          top: 16px;
        }
      }
    }
  }

  .grid {
    margin: -40px auto 0 auto;
    width: fit-content;
    width: 40%;
    display: grid;
    grid-template-columns: repeat(4,15%);
    grid-gap: 50px 13%;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 100%;
      max-height: 80px;
      object-fit: contain;
    }

    @media screen and (max-width: 480px) {
      width: 60%;
      margin: -25px auto 30px auto;
      grid-template-columns: repeat(2,35%);
      grid-gap: 30px 30%;
    }
  }
`;

// Seguros
export const Seguros = styled.section`
  
  .topo {
    position: relative;

    img {
      width: 100%;
      position: relative;
      z-index: -1;

      @media screen and (max-width: 480px) {
        width: 250%;
        right: 100%;
      }
    }

    div {
      position: absolute;
      z-index: 9;
      color: #fff;
      padding-left: 40px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-55%);
      bottom: 22.5%;
      transition: all ease-in .3s;

      @media screen and (max-width: 480px) {
        bottom: 8vh;
        top: inherit;
      }

      @media screen and (max-width: 320px) {
        bottom: 7vh;
        top: inherit;
      }

      h2 {
        font-weight: 300;
        position: relative;
        font-size: 40px;
        color: #fff;
        font-weight: 900;
        margin-bottom: -10px;

        :after {
          content: url(${require('../../assets/icone.svg')});
          position: absolute;
          left: -60px;
          top: 16px;
        }
      }
    }


  }

  .grid {
    margin: 0 auto 60px auto;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px 0;

    @media screen and (max-width: 480px) {
      grid-template-columns: 50% 50%;
      grid-gap: 10px 0;
      width: 100%;
    } 

    .box {
      text-align: center;
      transition: all ease-in .3s;
      position: relative;

      div {
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 30px;
      }

      img {
        height: 40px;
        margin: 0;
      }

      .losango {
        position: absolute;
        z-index: -1;
        height: 130px;
      }

      h3 {
        font-weight: bold;
        font-size: 18px;
        margin-top: 5px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .grid:first-of-type {
    margin-left: 60px;

    @media screen and (max-width: 480px) {
      margin-left: auto;
    }
  }
`;

// Consorcios
export const Consorcios = styled.section`
  position: relative;

  .topo {
    position: relative;

    img {
      width: 100%;
      position: relative;
      z-index: -1;

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    > div {
      position: relative;
      z-index: 9;
      color: #707070;
      padding-left: 0;
      width: 70%;
      padding-right: 10%;

      @media screen and (max-width: 480px) {
        padding: 0;
      }

      h2 {
        font-weight: 300;
        position: relative;
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 5px;
        margin-top: 30px;
        color: #707070;

        :after {
          content: url(${require('../../assets/icone.svg')});
          position: absolute;
          left: -60px;
          top: 16px;
        }
      }
    }
  }

  .grid {
    margin: 30px 0 60px 20px;
    width: 600px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 480px) {
      grid-template-columns: 50% 50%;
      grid-gap: 10px 0;
      width: 100%;
      margin: 30px 0 60px 0;
    }

    .box {
      text-align: center;
      transition: all ease-in .3s;
      position: relative;

      div {
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 30px;
      }

      img {
        height: 40px;
        margin: 0;
      }

      .losango {
        position: absolute;
        z-index: -1;
        height: 130px;
      }

      h3 {
        font-weight: bold;
        font-size: 18px;
        margin-top: 5px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .casal {
    position: absolute;
    right: 0;
    bottom: -20%;
    z-index: -1;
    width: 55%;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`;

// Blog
export const Blog = styled.section`

  .topo {
    position: relative;

    img {
      width: 100%;
      position: relative;
      z-index: -1;

      @media screen and (max-width: 480px) {
        width: 180%;
        right: 45%;
      }
    }

    div {
      position: absolute;
      z-index: 9;
      color: #fff;
      padding-left: 40px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-55%);
      top: 60%;

      @media screen and (max-width: 480px) {
        top: 56%;
      }

      h2 {
        font-weight: 300;
        position: relative;
        font-size: 40px;
        color: #fff;
        font-weight: 900;
        margin-bottom: 0;

        :after {
          content: url(${require('../../assets/icone.svg')});
          position: absolute;
          left: -60px;
          top: 16px;
        }
      }
    }
  }

  .grid {
    margin: 30px auto 60px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @media screen and (max-width: 480px) {
      grid-template-columns: 100%;
      margin: 30px auto 0 auto;
    }

    article {
      text-align: left;
      color: #636363;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        height: 250px;
      }

      h3 {
        font-weight: bold;
        font-size: 18px;
        margin-top: 5px;
      }

      button {
        background: #DE1A2B;
        color: #fff;
        width: auto;
        margin: 20px 0;
        display: block;
        border: none;
        height: 30px;
        border-radius: 50px;
        font-weight: 900;
        font-size: 14px;
        padding: 0 20px;
        cursor: pointer;
        transition: all ease-in .3s;

        @media screen and (max-width: 480px) {
          margin: 20px auto 45px auto;
        }

        :hover {
          background: #535352;
        }
      }

      span {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        display: block;
        margin-top: 20px;
      }

      div {
        height: 110px;
      }
    }
  }

  hr {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    border-color: #DE1A2B;
    color: #DE1A2B;
    background: #DE1A2B;
    
    @media screen and (max-width: 480px) {
      width: 87.5%;
      max-width: 87.5%;
    }
  }
  

  .vejaMais {
    text-align: center;
    display: block;
    margin: 30px auto 100px auto;
    font-weight: bold;
    color: #000000;
    font-size: 20px;
    transition: all ease-in .3s;

    @media screen and (max-width: 480px) {
      margin: 30px auto 60px auto; 
    }

    :hover {
      color: #DE1A2B;
    }
  }
`;

// Mapa
export const Mapa = styled.section`
  margin-bottom: 75px;

  img {
    position: relative;
    width: 100%;
  }
/* 
  iframe {
    position: relative;
    z-index: 7;
  } */
`;

// Contatos
export const Contatos = styled.section`
  display: flex;
  margin-top: 30px;
  padding-left: 4%;
  position: relative;

  @media screen and (max-width: 480px) {
    display: inherit;
  }

  h2 {
    position: relative;
    font-size: 40px;
    color: #707070;
    font-weight: 900;
    margin-bottom: 0;
    margin-left: -10px;
    width: 270px;

    :after {
      content: url(${require('../../assets/icone.svg')});
      position: absolute;
      left: -60px;
      top: 16px;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
      margin-left: 5px;
    }
  }

  strong {
    font-weight: 900;
  }

  div {
    address {
      margin-top: 25px;

      p {
        margin-bottom: 0;
      }
    }

    .social {
      margin-bottom: 40px;
      
      h4 {
        font-weight: 900;
        font-size: 16px;
      }

      div {
        display: flex;

        img {
          margin-right: 15px;
          height: 40px;
        }
      }
    }

    .brasil {
      position: absolute;
      right: 0%;
      width: 420px;
      top: 12.5%;

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    hr {
      width: 100%;
      border-color: #DE1A2B;
      color: #DE1A2B;
      background: #DE1A2B;
    }

    h4 {
      font-size: 26px;
      font-weight: 900;
      margin-top: 50px;
      color: #535352;
      line-height: 28px;
    }

    h3 {
      font-size: 20px;
      color: #7B7B7A;
      line-height: 22px;
      margin-top: 50px;
    }

    form {
      width: 340px;
      position: relative;
      z-index: 90;
      margin-bottom: 50px;

      @media screen and (max-width: 480px) {
        width: 100%;
      }

      input {
        height: 40px;
      }

      textarea {
        padding-top: 15px;
      }

      input, textarea {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        text-indent: 15px;
        background: #DDDDDD;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        border-radius: 5px;
        margin-bottom: 20px;
        border: 2px solid #DDDDDD;
        transition: all ease-in .3s;

        :focus {
          background: none;
        }
      }
    }

    button {
      background: #DE1A2B;
      color: #fff;
      width: auto;
      margin: 20px 0;
      display: block;
      border: none;
      border-radius: 50px;
      font-weight: 900;
      font-size: 16px;
      padding: 5px 25px;
      cursor: pointer;
      transition: all ease-in .3s;

      :hover {
        background: #535352;
      }

      &.acionar {
        background: #4AC959;

        :hover {
          background: #455A64;
        }
      }
    }

    .sinistro {
      margin-top: 40px;
      width: 370px;
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }

`;
