import styled from 'styled-components';

// CTA
export const CTA = styled.section`
  background: #DE1A2B;
  margin-bottom: -75px;
  position: relative;
  padding: 70px 0;
  margin-top: 200px;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      text-align: center;
    }

    p {
      margin: 0;
      color: #fff;
      font-size: 26px;
    }

    button {
      background: #535352;
      color: #fff;
      width: auto;
      margin: 0 0 0 35px;
      display: block;
      border: none;
      border-radius: 50px;
      font-weight: 900;
      font-size: 16px;
      padding: 5px 25px;
      cursor: pointer;
      -webkit-transition: all ease-in .3s;
      transition: all ease-in .3s;

      :hover {
        background: #fff;
        color: #535352;
      }

      @media screen and (max-width: 480px) {
        margin: 20px 0 0 0;
      }
    }
  }

  .icone {
    position: absolute;
    bottom: 0;
    z-index: -1;

    @media screen and (max-width: 480px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

// Blog
export const Blog = styled.section`

  .topo {
    position: relative;

    img {
      width: 100%;
      position: relative;
      z-index: -1;

      @media screen and (max-width: 480px) {
        width: 180%;
        right: 45%;
      }
    }

    div {
      position: absolute;
      z-index: 9;
      color: #fff;
      padding-left: 40px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-55%);
      top: 60%;

      @media screen and (max-width: 480px) {
        top: 56%;
      }

      h2 {
        font-weight: 300;
        position: relative;
        font-size: 40px;
        color: #fff;
        font-weight: 900;
        margin-bottom: 0;

        :after {
          content: url(${require('../../assets/icone.svg')});
          position: absolute;
          left: -60px;
          top: 16px;
        }
      }
    }
  }

  .grid {
    margin: 30px auto 60px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @media screen and (max-width: 480px) {
      grid-template-columns: 100%;
      margin: 30px auto 0 auto;
    }

    article {
      text-align: left;
      color: #636363;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        height: 250px;
      }

      h3 {
        font-weight: bold;
        font-size: 18px;
        margin-top: 5px;
      }

      button {
        background: #DE1A2B;
        color: #fff;
        width: auto;
        margin: 20px 0;
        display: block;
        border: none;
        height: 30px;
        border-radius: 50px;
        font-weight: 900;
        font-size: 14px;
        padding: 0 20px;
        cursor: pointer;
        transition: all ease-in .3s;

        @media screen and (max-width: 480px) {
          margin: 20px auto 45px auto;
        }

        :hover {
          background: #535352;
        }
      }

      span {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        display: block;
        margin-top: 20px;
      }

      div {
        height: 110px;
      }
    }
  }
`;