import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// api
import api from '../../services/api';

// styles
import { Blog, CTA } from './styles';

export default function Seguro( {match} ) {
  const { slug } = match.params;
  const [posts, setDados] = useState();

  useEffect(() => {
    async function loadDados() {
      await api
        .get(`/posts?_embed`, {})
        .then(response => {
          setDados(response.data);
        });
    }
    loadDados();
  }, [slug]);

  // formatar data
  function formataData( data ) {
    var date = new Date(data);

    let dataFormatada = date.toLocaleString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).replace(/\//g, '/');

    return dataFormatada;
  }

  return (
    <>
      <Helmet>
        <title>
          Blog - AgilPress
        </title>

        <meta
          name="description"
          content="Atendendo o mercado em diversas áreas e cenários surgiu então a demanda de proteger não só a marca, mas também os bens de nossos clientes, nossa expertise mostrou que a tranquilidade está relacionada à proteção total do seu patrimônio."
        />
        <meta
          name="keywords"
          content="seguros, consórcios"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>

      <Header internas />
        
      <main> 
        <Blog id="blog">
          <div className="topo">
            <img src={require('../../assets/bg_blog.png')} alt="Background Seguradoras" />
            
            <div className="container">
              <h2>Blog</h2>
            </div>
          </div>

          <div className="grid container">
            {!!posts && posts.map( (post, index) => (
              <a key={index} href={`blog/${post.slug}`}>
                <article>
                  <img
                        src={
                          post.featured_media
                            ? post._embedded['wp:featuredmedia']['0'].source_url
                            : 'https://www.uergs.edu.br/themes/modelo-noticias/images/outros/GD_imgSemImagem.png?classificacao=34752'
                        }
                        alt={post.title.rendered}
                      />
                  <span>{formataData(post.date)}</span>
                  <h3>{post.title.rendered}</h3>
                  <div>{ReactHtmlParser(post.excerpt.rendered)}</div>
                  <button>Continuar lendo</button>
                </article>
              </a>
            ))}
          </div>
        </Blog>

        <CTA>
          <div className="container">
            <p>Deseja fazer uma cotação?</p>
            <a href="#!">
              <button>Solicitar cotação agora</button>
            </a>
          </div>

          <img alt="" className="icone" src={require('../../assets/seguros/losango.svg')} />
        </CTA>
      </main>

      <Footer />
    </>
  );
}
