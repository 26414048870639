import React from 'react';

// styles
import { Wrapper } from './styles';

export default function Footer() {

  return (
    <>
      <Wrapper>
        <div className="container">
          <img className="brasil" src={require('../../assets/simbolo_agilpress.svg')} alt="Símbolo AgilPress" />

          <h3>Siga-nos nas redes sociais:</h3>
          <a href="http://facebook.com" target="__blank">
            <img className="social" src={require('../../assets/facebook.svg')} alt="Ícone Facebook" />
          </a>
          
          <a href="https://www.instagram.com" target="__blank">
            <img className="social" src={require('../../assets/instagram.svg')} alt="Ícone Instagram" />
          </a>

          <div className="textos">
            <a href="tel:+5504233237671">
              <h4>(42) 3323-7671</h4>
            </a>
            <a target="__blank" href="https://api.whatsapp.com/send?phone=5542991279326&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20%20">
              <h4>(42) 99127-9326 (WhatsApp)</h4>
            </a>
            <p>falecom@agilpressseguros.com.br</p>
            <p>Rua Pedro Mascarenhas Ribas, 412 - CEP 84.015-760 - Ponta Grossa, PR</p>
            <p>
              <strong>AgilPress Consultores Associados ​© 2020.</strong> Fotos meramente ilustrativas. Todos os direitos reservados.
            </p>
          </div>
         
          <p>
            Desenvolvido com <img className="amor" src={require('../../assets/amor.svg')} alt="Feito com amor" /> por <a className="ade" href="htts://agenciaade.com.br" target="__blank">agencia<span>ade</span>.com.br</a>
          </p>
        </div>
      </Wrapper>
    </>
  );
}
