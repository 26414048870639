import styled from 'styled-components';

// Wrapper
export const Wrapper = styled.section`
  position: relative;
  padding-top: 120px;

  @media screen and (max-width: 480px) {
    padding-top: 110px;
  }

  .topo {
    position: relative;
    background: #686868;
    padding: 10px 0 25px 0;

    .container {
      display: flex;
      position: relative;

      @media screen and (max-width: 480px) {
        display: inherit;
        padding: 20px 0 15px 0;
      }

      h1 {
        font-weight: 900;
        position: relative;
        font-size: 36px;
        color: #fff;
        margin-top: 3vh;

        @media screen and (max-width: 480px) {
          margin-left: 0;
          font-size: 24px;
          margin-top: inherit;
          width: 80%;
        }

        @media screen and (max-width: 320px) {
          font-size: 22px;
        }

        strong {
          font-weight: bold;
        }

        :after {
          content: url(${require('../../assets/icone.svg')});
          position: absolute;
          left: -60px;
          top: 16px;

          @media screen and (max-width: 480px) {
            top: 50%;
            transform: translateY(-50%);
            height: 35px;
          }
        }
      }

      > div {
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5% 0 0 10%;
        position: absolute;
        width: 150px;
        right: 10%;

        @media screen and (max-width: 480px) {
          height: 130px;
          width: 115px;
          position: absolute;
          right: 0%;
          bottom: -65px;
        }

        @media screen and (max-width: 320px) {
          height: 100px;
          width: 90px;
          bottom: -50px;
        }

        img {
          height: 50px;
          position: relative;
          z-index: 4;
        }

        .losango {
          position: absolute;
          z-index: 3;
          height: 170px;
          left: 0;
          bottom: 0;

          @media screen and (max-width: 480px) {
            height: 130px;
          }

          @media screen and (max-width: 320px) {
            height: 100px;
          }
        }
      }
    }
  }

  .conteudo {
    text-align: left;
    margin-top: 30px;
    width: 65%;
    padding-bottom: 200px;
    line-height: 30px;
    font-size: 18px;

    @media screen and (max-width: 480px) {
      width: 95%;
      padding-bottom: 100px;
      font-size: 16px;
      margin-top: 75px;
    }
  }

  .carro {
    position: absolute;
    right: -15%;
    bottom: -2px;
    width: 100%;
    max-width: 1000px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`;

// CTA
export const CTA = styled.section`
  background: #DE1A2B;
  margin-bottom: -75px;
  position: relative;
  padding: 70px 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      text-align: center;
    }

    p {
      margin: 0;
      color: #fff;
      font-size: 26px;
    }

    button {
      background: #535352;
      color: #fff;
      width: auto;
      margin: 0 0 0 35px;
      display: block;
      border: none;
      border-radius: 50px;
      font-weight: 900;
      font-size: 16px;
      padding: 5px 25px;
      cursor: pointer;
      -webkit-transition: all ease-in .3s;
      transition: all ease-in .3s;

      :hover {
        background: #fff;
        color: #535352;
      }

      @media screen and (max-width: 480px) {
        margin: 20px 0 0 0;
      }
    }
  }

  .icone {
    position: absolute;
    bottom: 0;
    z-index: -1;

    @media screen and (max-width: 480px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
