import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Seguro from '../pages/Seguro';
import Consorcio from '../pages/Consorcio';
import Blog from '../pages/Blog';
import Post from '../pages/Blog/Post';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/seguros/:slug"  component={Seguro} />
      <Route path="/consorcios/:slug"  component={Consorcio} />
      
      <Route path="/blog" exact component={Blog} />
      <Route path="/blog/:slug" component={Post} />
    </Switch>
  );
}
