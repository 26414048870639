import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// api
import api from '../../services/api';

// styles
import { Wrapper, CTA } from './styles';

export default function Consorcio( {match} ) {
  const { slug } = match.params;
  const [dados, setDados] = useState();

  useEffect(() => {
    async function loadDados() {
      await api
        .get(`/consorcios?slug=${slug}`, {})
        .then(response => {
          setDados(response.data);
        });
    }
    loadDados();
  }, [slug]);


  return (
    <>
      <Helmet>
        <title>
          {`${
            !!dados ? dados[0].title.rendered : 'Consórcio'
          } - AgilPress`}
        </title>

        <meta
          name="description"
          content="Atendendo o mercado em diversas áreas e cenários surgiu então a demanda de proteger não só a marca, mas também os bens de nossos clientes, nossa expertise mostrou que a tranquilidade está relacionada à proteção total do seu patrimônio."
        />
        <meta
          name="keywords"
          content="seguros, consórcios"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>

      <Header internas />

      {!!dados && dados.map( (consorcio,index) => (
        <main key={index}> 
          <Wrapper>
            <div className="topo">
              <div className="container">
                <h1>{consorcio.title.rendered}</h1>
                <div>
                  <img className="losango" src={require('../../assets/losango_icone.svg')} alt="Ícone Losango" />
                  <img src={consorcio.acf.icone} alt={consorcio.title.rendered} />
                </div>
              </div>
            </div>

            <div className="container">
              <div className="conteudo">
                {ReactHtmlParser(consorcio.acf.descricao)}
              </div>
            </div>

            <img className="carro" src={require('../../assets/consorcio.png')} alt="Imagem automóvel" />
          </Wrapper>

          <CTA>
            <div className="container">
              <p>Quer fazer uma cotação desse consórcio?</p>
              <a href="#!">
                <button>Solicitar cotação!</button>
              </a>
            </div>

            <img alt="" className="icone" src={require('../../assets/seguros/losango.svg')} />
          </CTA>
        </main>
      ))}
      <Footer />
    </>
  );
}
